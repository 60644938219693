import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button, Grid, ReportIcon, Stack, Typography } from '@esgian/esgianui';

import RigDemandTimelineContainer from '@components/Sections/OilCompanySection/RigDemandTimelineContainer/RigDemandTimelineContainer';
import SaveToFileButton from '../../Inputs/SaveToFileButton';
import { getProject } from '@helpers/helpers';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { useParams } from 'react-router-dom';
import { useStore } from '@store/Store';

function OilCompanySection({ project }) {
  const params = useParams();
  const {
    state: {
      projectsData: { projects }
    }
  } = useStore();

  const exportPdf = useCallback(async () => {
    let legend = document.querySelector('#timeline-legend');
    legend.style.width = '100vw';
    let timeline = document.querySelector('#rig-demand-timeline');
    let prevHeight = timeline.style.height;
    timeline.style.height = 'fit-content';
    timeline.style.maxHeight = 'fit-content';

    const { projectName, note, createdBy, createdDate, lastEditBy, lastEditDate } = getProject(
      projects,
      params.projectId
    );

    const imgWidth = 275;
    const pageHeight = 210;
    let position = 35;
    const doc = new jsPDF('landscape', 'mm', 'a4');

    // Capture the legend
    await html2canvas(legend).then((canvas) => {
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      const imgData = canvas.toDataURL('image/png');
      doc.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight);
      position += imgHeight + 5;
    });

    legend.removeAttribute('style');

    // Capture the timeline
    await html2canvas(timeline).then((canvas) => {
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      const totalHeight = canvas.height;
      const scale = canvas.width / imgWidth; // Scale factor based on canvas and PDF width

      let remainingHeight = totalHeight / scale;
      let currentPosition = position;

      // Check if content fits on the current page
      if (remainingHeight <= pageHeight - currentPosition) {
        // If it fits, add the entire image
        const imgData = canvas.toDataURL('image/png');
        doc.addImage(imgData, 'PNG', 10, currentPosition, imgWidth, remainingHeight);
      } else {
        // Otherwise, break into two pages
        let startY = 0;

        // First page: fits the content that can go on the current page
        const firstPageHeight = pageHeight - currentPosition;
        let pageCanvas = document.createElement('canvas');
        pageCanvas.width = canvas.width;
        pageCanvas.height = firstPageHeight * scale;
        let ctx = pageCanvas.getContext('2d');
        ctx.drawImage(
          canvas,
          0,
          startY * scale,
          canvas.width,
          firstPageHeight * scale,
          0,
          0,
          canvas.width,
          firstPageHeight * scale
        );

        const imgDataFirstPage = pageCanvas.toDataURL('image/png');
        doc.addImage(imgDataFirstPage, 'PNG', 10, currentPosition, imgWidth, firstPageHeight);

        // Move to the next page and add the remaining content
        doc.addPage();
        startY += firstPageHeight;

        const remainingPageHeight = remainingHeight - firstPageHeight;
        pageCanvas = document.createElement('canvas');
        pageCanvas.width = canvas.width;
        pageCanvas.height = remainingPageHeight * scale;
        ctx = pageCanvas.getContext('2d'); // Reassigning ctx with "let"
        ctx.drawImage(
          canvas,
          0,
          startY * scale,
          canvas.width,
          remainingPageHeight * scale,
          0,
          0,
          canvas.width,
          remainingPageHeight * scale
        );

        const imgDataSecondPage = pageCanvas.toDataURL('image/png');
        doc.addImage(imgDataSecondPage, 'PNG', 10, 10, imgWidth, remainingPageHeight);
      }

      // Add footer and text for all pages
      doc.setFontSize(10).setFont(undefined, 'normal');
      for (let i = 1; i <= doc.getNumberOfPages(); i++) {
        doc.setPage(i);
        doc.text(`Page ${i} of ${doc.getNumberOfPages()}`, imgWidth - 8, i === 1 ? 25 : 7);
      }

      // Add project information on the first page
      doc.setPage(1);
      doc.setFontSize(10).setFont(undefined, 'bold');
      doc.text('Project name:', 10, 10);
      doc.text('Note:', 10, 15);
      doc.text('Created By:', 10, 20);
      doc.text('Created:', 10, 25);
      doc.text('Last edited by:', 80, 20);
      doc.text('Last edited:', 80, 25);

      doc.setFont(undefined, 'normal');
      doc.text(projectName, 40, 10);
      doc.text(note || '-', 40, 15);
      doc.text(createdBy, 40, 20);
      doc.text(createdDate, 40, 25);

      doc.text(lastEditBy, 110, 20);
      doc.text(lastEditDate, 110, 25);
    });

    doc.save('rigs-planner-summary.pdf');

    // Reset the timeline styles
    timeline.style.height = prevHeight;
    timeline.style.maxHeight = '75vh';
  }, [JSON.stringify(projects)]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Stack direction={'row'} justifyContent={'space-between'}>
          <Typography variant={'h5'} bold>
            WORKBOOK
          </Typography>
          <Stack direction={'row'} spacing={2}>
            <Button
              variant={'text'}
              onClick={exportPdf}
              sx={{ color: '#000000' }}
              startIcon={<ReportIcon color={'inherit'} />}>
              Export
            </Button>
            <SaveToFileButton projectId={parseInt(params.projectId)} />
          </Stack>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <RigDemandTimelineContainer project={project} />
      </Grid>
    </Grid>
  );
}

OilCompanySection.propTypes = {
  project: PropTypes.object.isRequired
};

OilCompanySection.defaultProps = {};

export default OilCompanySection;
