import { GENERAL_ACTIONS, getUserFirstAndLast } from '@store/AppReducer';
import moment from 'moment';
import { initializeAppInsights, updateTelemetryInitializer } from '@hooks';

export const getGeneralActions = (state, action) => {
  switch (action.type) {
    case GENERAL_ACTIONS.UPDATE_THEME_MODE:
      return { ...state, themeMode: action.payload.themeMode };
    case GENERAL_ACTIONS.UPDATE_MENU_OPEN:
      return { ...state, sideMenuOpen: action.payload.sideMenuOpen };
    case GENERAL_ACTIONS.SET_USER:
      if (action.payload.user) {
        initializeAppInsights();
        updateTelemetryInitializer(action.payload.user);
      }
      return { ...state, user: action.payload.user };
    case GENERAL_ACTIONS.SET_LOOKUP_DATA:
      return {
        ...state,
        ...{
          filterLookups: action.payload.filterLookups
        }
      };
    case GENERAL_ACTIONS.SET_PROJECTS_DATA:
      return { ...state, projectsData: action.payload.projectsData };

    case GENERAL_ACTIONS.CREATE_PROJECT: {
      const tempState = { ...state };
      tempState.projectsData.projects.push({
        ...action.payload.project,
        projectId: tempState.projectsData.index
      });
      tempState.projectsData.index = tempState.projectsData.index + 1;
      return tempState;
    }
    case GENERAL_ACTIONS.UPDATE_PROJECT: {
      const { projectId, projectName, note } = action.payload;
      const tempState = { ...state };
      const projectsCopy = [...tempState.projectsData.projects];
      const projectIndex = projectsCopy.findIndex(
        (project) => parseInt(project.projectId) === parseInt(projectId)
      );

      if (projectIndex === -1) return tempState;

      projectsCopy[projectIndex].projectName = projectName;
      projectsCopy[projectIndex].note = note;
      projectsCopy[projectIndex].lastEditDate = moment().format('DD/MM/yyyy');
      projectsCopy[projectIndex].lastEditBy = getUserFirstAndLast(tempState);
      tempState.projectsData.projects = projectsCopy;

      return tempState;
    }
    case GENERAL_ACTIONS.DELETE_PROJECT: {
      const tempState = { ...state };
      tempState.projectsData.projects = tempState.projectsData.projects.filter(
        ({ projectId }) => projectId !== action.payload.projectId
      );
      return tempState;
    }
    case GENERAL_ACTIONS.SET_RIG_SUPPLY_CHART_SETTING: {
      const tempState = { ...state };
      tempState.sectionFilters.rigSupply.chartSettings = action.payload.chartSettings;
      return tempState;
    }
    case GENERAL_ACTIONS.ADD_OR_UPDATE_COMMENT: {
      const { projectId, comment, resourceId, eventId = null } = action.payload;
      const tempState = { ...state };
      const projectsCopy = [...tempState.projectsData.projects];
      const projectIndex = projectsCopy.findIndex(
        (project) => parseInt(project.projectId) === parseInt(projectId)
      );

      if (projectIndex === -1) return tempState;

      let commentIndex = projectsCopy[projectIndex].comments.findIndex(
        (item) => item.resourceId === resourceId && eventId === item.eventId
      );
      if ((!comment || comment === '') && commentIndex > -1) {
        projectsCopy[projectIndex].comments[commentIndex].comment = projectsCopy[
          projectIndex
        ].comments.splice(commentIndex, 1);
      } else {
        if (commentIndex > -1) {
          projectsCopy[projectIndex].comments[commentIndex].comment = comment;
        } else {
          projectsCopy[projectIndex].comments.push({
            comment: comment,
            resourceId: resourceId,
            eventId: eventId
          });
        }
      }
      projectsCopy[projectIndex].lastEditDate = moment().format('DD/MM/yyyy');
      projectsCopy[projectIndex].lastEditBy = getUserFirstAndLast(tempState);
      tempState.projectsData.projects = projectsCopy;

      return tempState;
    }

    case GENERAL_ACTIONS.REMOVE_COMMENT: {
      const { projectId, resourceId, eventId = null } = action.payload;
      const tempState = { ...state };
      const projectsCopy = [...tempState.projectsData.projects];
      const projectIndex = projectsCopy.findIndex(
        (project) => parseInt(project.projectId) === parseInt(projectId)
      );

      if (projectIndex === -1) return tempState;

      projectsCopy[projectIndex].comments = projectsCopy[projectIndex].comments?.filter((item) => {
        if (eventId) {
          return item.resourceId !== resourceId || item.eventId !== eventId;
        }
        return item.resourceId !== resourceId;
      });
      projectsCopy[projectIndex].lastEditDate = moment().format('DD/MM/yyyy');
      projectsCopy[projectIndex].lastEditBy = getUserFirstAndLast(tempState);
      tempState.projectsData.projects = projectsCopy;

      return tempState;
    }

    default:
      return state;
  }
};
